import { Container, Row,Col } from "react-bootstrap";

function BuildingCareer(){
    return(
        <>
          <section className="buildingCareer">
            <Container>
                <Row>
                    <Col>
                    <p style={{ textAlign: "right", }}>
                 <a style={{ backgroundColor: "black", color: "white" }}>INVITE US TO A COLLEGE WEBINAR</a>
         
            </p>


                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xl={12}>
                        <img src="./images/events/policy_series/innerPage/buildingcareer/quote.png" />
                    </Col>
                    <Col xl={12}>
                        <h5 className="text-white" style={{fontSize:"55px", fontWeight: "700"}}>
                        Let’s Talk <br/>
Policy
                        </h5>
                        <h6 className="text-white" style={{fontSize:"42px", fontWeight: "600"}}>
                        Building a Career in a <br />
Multilateral Organization
                        </h6>
                        <p className="text-white">
                        3rd June, 2022 | 07:00pm – 08:00pm
                        </p>
                    </Col>
                </Row>
            </Container>
         
          </section>
          <section className="section-bg">
            <Container>
                <Row>
                    <Col xl={12}>
                        <p>
                        Multilateral organizations are formed by three or more nations to work on issues
                         that are relevant to each of them. They are inclusive and permit participation by 
                         all in the management of world affairs
                         thereby ensuring the legitimacy of their efforts being widely implemented.
                        </p>
                      <p>
                      Working for Multilateral organizations enables individuals to create change 
                       a macro level. Individuals are exposed to different countries and settings and
                        obtain diverse skillsets . Working for these organizations requires individuals 
                        to be critical thinkers, 
                      have a global outlook, and be willing to adapt to constant changes.
                      </p> 
                      <p>
                      The event is organised to provide students with an understanding of how to plan
                       and approach a career in a multilateral organization. They get to hear first hand from global experts, about the technical expertise, soft skills and the networks required to work in such
                       organizations and also about how a Master’s Degree in Public Policy can help them.
                      </p>
                    </Col>
                </Row>
            </Container>

          </section>

          <section className="buildingCareer2">
<Container>
    <Row>
        <Col xl={12}>
            <h4 className="text-primary">
            About the panelists
            </h4>

        </Col>
        <Col xl={4}>
            <img src="images\events\policy_series\innerPage\buildingcareer\pannel1.png" />
        
        </Col>
        <Col xl={4}>
            <img src="images\events\policy_series\innerPage\buildingcareer\pannel2.png" />
        
        </Col>
        <Col xl={4}>
            <img src="images\events\policy_series\innerPage\buildingcareer\pannel3.png" />
        
        </Col>

    </Row>
</Container>
          </section>

          <div className="bg-primary p-5">
<Container>
    <Row>
        <Col xl={12}>
       
			<p className="text-white"><strong>Aspects to be discussed:</strong></p>
<p className="text-white">Individuals aiming to work for multilateral organizations are tasked with responsibilities that no country can address alone. In this very diverse field, professionals work across a wide range of careers, including bettering living standards and human rights, maintaining international peace and security, promoting social progress, and developing friendly relations between nations. International organizations (IO) work throughout the world and employ people with diverse backgrounds and work histories with specialised knowledge about the functioning available through advanced degrees.</p>
<p className="text-white">With expert advisors from the World Bank, UN, IMF and top faculty for International relations, the Master’s degree in Public Policy at Kautilya provides a holistic understanding of the technical expertise, soft skills and networking required to prepare students for a career in multilateral organisations.</p>
<p className="text-white">With the industry-linked courses on International Relations, mentorship from our senior leadership team who have worked at the highest positions in various multilateral, learning key soft skills such as multi-party negotiations, persuasion, diplomacy, advocacy and unlocking a network to get internships &amp; placements in these organisations is how Kautilya aims to nurture the next generation of changemakers at the global stage.</p>

		
        </Col>
    </Row>
</Container>
          </div>

          <section className="buildingCareer3">
<Container>
    <Row>
        <Col xl={12}>
<p>
<strong>What will the attendees takeaway from the event: –</strong>
</p>
<ul className="entry_content">
<li>A holistic view of how to plan for a career in Multilateral Organisations.</li>
<li>Guidance from esteemed speakers who have in-depth knowledge and experience working with International Organisations</li>
<li>Details on how Kautilya’s MPP can aid in forging an effective career in global organisations. Understand MPP course offerings in the stream of International Relations and how key soft skills will be taught by experts.</li>
<li>Information on Kautilya’s network and how we can offer internships and placements to help you in the pathway towards working in a multilateral organisation.</li>
</ul>
        </Col>
        </Row>
        </Container>
        </section>
        </>
    );
}
export default BuildingCareer;