import { Col, Container, Row } from "react-bootstrap";
import { useState, useEffect } from 'react';
import axios from "axios";
import Faculty from "../components/Faculty";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import Tabs_section from "../components/Tabs";
import withReactContent from 'sweetalert2-react-content';
function AluminiNewwork() {
    const MySwal = withReactContent(Swal);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        city: '',
        designation: '',
        sector: '',
    });

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            // Send the form data to the server
            const response = await fetch('https://guprojects.gitam.edu/kautilya-admin/api/savealumniassociation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            // Handle the server response
            const data = await response.json();
            setFormData({
                name: '',
                email: '',
                mobile: '',
                city: '',
                designation: '',
                sector: '',
            });
            // Show SweetAlert success message
            if (data.status == "success") {
                MySwal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: data.message,
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: data.message,
                });

            }

        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error and show SweetAlert error message if needed
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to submit the form. Please try again.',
            });
        }
    };



    //alumni profile
    const [alumniprofileData, setAlumniprofileData] = useState([]);
    useEffect(() => {
        fetchalumniprofileData();
    }, []);
    const fetchalumniprofileData = async () => {
        try {
            const response = await axios.get(
                "https://guprojects.gitam.edu/kautilya-admin/api/fetch-alumniprofiles"
            ); // Replace with your actual API endpoint
            setAlumniprofileData(response.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    console.log("alumni profiles", alumniprofileData);



    return (
        <>
            <section>
                <Container>
                    <Row>
                        <Col xl={12} className="mt-4">
                            <a onClick={handleShow}><img src="../images/alumini/Web-Desktop-Creatives-29-scaled.jpg" alt="banner imaage" /></a>
                            <Modal show={show} onHide={handleClose} className='faculty_modal'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Alumni Association</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col xl={12}>
                                                <Form.Group className="mb-3" controlId="formName">
                                                    <Form.Control type="text" placeholder="Full Name *" name="name" value={formData.name} onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control placeholder="Email ID *" type="email" name="email" value={formData.email} onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="text" placeholder="Mobile *" minlength="10" maxlength="10" name="mobile" value={formData.mobile} onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="text" placeholder="Current City" name="city" value={formData.city} onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="text" placeholder="Current Designation" name="designation" value={formData.designation} onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="text" placeholder="sector" name="sector" value={formData.sector} onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                            <Col xl={12} className="text-center">
                                                <Button variant="danger" type="submit" >
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Modal.Body>
                            </Modal>
                        </Col>

                    </Row>
                </Container>
            </section>
            <hr />
            <section>
                <Container>
                    <Row>
                        <Col xl={6}>
                            <h5 className="alu_head">Alumni Network</h5>
                            <img src="../images/alumini/Punjab.jpg" alt=" map" />
                        </Col>
                        <Col xl={6}>
                            <h5 className="alu_head alu_speak">Alumni Speaks</h5>
                            <Row>
                                <Col xl={12} className="mb-5">
                                    <iframe width="100%" height="313" src="https://www.youtube.com/embed/3bZyjdH3Mfc" title="Switching tracks: Introducing Nikhitha Jagadeesh &amp; Rawson Gonzalves, Kautilya MPP students" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </Col>
                                <Col xl={12}>
                                    <iframe width="100%" height="313" src="https://www.youtube.com/embed/jGmhecg9WG0" title="Entering the industry | MPP graduate Manogna Atkuru" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <hr />
            <section className="alumnifaculty">
                <Container>
                    <Row>
                        <Col xl={12}>
                            <h5 className="alu_head">Alumni Profiles</h5>
                        </Col>
                        <Col xl={12}>

                            <Faculty faculty={alumniprofileData} />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
export default AluminiNewwork;