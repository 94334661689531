import { Col, Container, Row } from "react-bootstrap";
function Augusttalkpolicy(){
    return(<>
    <div className="policy_talk">
    <section className="lets_talk">
            <Container>
                
                <Row className="mt-5">
               
                    <Col xl={6} className="mt-5">

                    <p class="eventoutline">PANEL DISCUSSION</p>
                    <div className="d-flex panel">
                    <span><img src="./images/events/policy_series/innerPage/quote.png"></img></span>
                    <p class="LTP-txt">Let’s Talk <br/>
                      <span className="bold mb-5">Policy</span></p>
                    </div>
                        <p class="LTP-tit sim_tit">“How can research and innovationin India be more impactful?”</p>
                        <div className="d-flex panel_cal">
                           <span><img src="./images/events/policy_series/innerPage/evenicon1.png"></img><span class="LTP-tit">August 3, 2021</span></span> 
                            <span><img src="./images/events/policy_series/innerPage/evenicon2.png"></img><span class="LTP-tit">6 PM IST</span></span>
                        </div>
                    </Col>
                    <Col xl={6} className="mt-5">
                     <img src="./images/events/policy_series/innerPage/LP-top-banner-PNG-1-1.png"></img>
                    </Col>
                </Row>

            </Container>
           </section>
           <section className="about_evnt">
            <Container>
               <h2>About the event</h2>
               <p>India has joined the top 50 countries in the <b>Global Innovation Index (GII, 2020) </b>for the first time, moving up four places to the 48th rank, keeping the leading position among nations in central and southern Asia.</p>
               <p>While India has the scientific talent, what stops Indian research and innovation from being genuinely impactful? Is it lack of funding support, the risk-averse mindset of investors, and lack of an enabling ecosystem or the absence of gender/racial parity in research?</p>
               <p>In this <b>Let’s Talk Policy session</b>, we looked at how to beef up the national innovation ecosystem with <b>Sridhar Pabbisetty</b>, Founding Director, Kautilya School of Public Policy, <b>Saket Modi</b>, CEO & Co-Founder, Safe Security, <b>Ashutosh Sharma</b>, Secretary, Department of Science and Technology (DST), Government of India and <b>Dr. Chandrima Shaha</b>, President, Indian National Science Academy, New Delhi.</p>
               <p>The event was moderated by renowned journalist, <b>Nidhi Razdan.</b></p>
               </Container>
           </section>
           <section className="yellow_bg">
            <Container>
               <p><span>‘Let’s Talk Policy’</span> <b>is a panel discussion series initiated by Kautilya School of Public Policy</b>, that brings forth a select group of panellists, sharing differing views on key issues of national and global relevance, and building the dialogue into a conversation for the student community.</p>
               <p>The series aims to unravel major themes around democracy, politics, and human rights, and explore the challenges and rewards of implementing practices. We aim to create a cohesive platform and call on thematic experts to foster a healthy dialogue around the most-pressing issues.</p>
               </Container>
           </section>
           <section className="panellists">
            <Container>
                <h2>About the panellists</h2>
                <Row>
                    <Col xl={4} className="bg_red">
                        <img src="./images/events/policy_series/innerPage/Nidi.png"></img>
                        <div className="">
                        <h5>Nidhi Razdan</h5>
                        <p className="k-eventcard5-desig">Visiting Faculty, Kautilya &<br/>
                        Fmr. Exec. Editor NDTV</p>
                        <p className="k-eventcard5-role">Moderator</p>
                        <p className="k-eventcard5-desc">Nidhi Razdan (Moderator) is an award-winning journalist, author and the former Executive Editor of…</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red bg_black">
                        <img src="./images/events/policy_series/innerPage/1-1.png"></img>
                        <div className="">
                        <h5>Sridhar Pabbisetty</h5>
                        <p className="k-eventcard5-desig">Founding Director, Kautilya</p>
                        <p className="k-eventcard5-role k-new">-------</p>
                        <p className="k-eventcard5-desc">Sridhar Pabbisetty has been working in public policy and governance over the last decade….</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red">
                        <img src="./images/events/policy_series/innerPage/2-2.png"></img>
                        <div className="">
                        <h5>Saket Modi</h5>
                        <p className="k-eventcard5-desig">CEO & Co-Founder,<br/>
                        Safe Security</p>
                        <p className="k-eventcard5-role ">------</p>
                        <p className="k-eventcard5-desc">Saket Modi is the Co-Founder and CEO of Safe Security, a Cybersecurity and Digital Business …</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red bg_black">
                        <img src="./images/events/policy_series/innerPage/3-3.png"></img>
                        <div className="">
                        <h5>Ashutosh Sharma</h5>
                        <p className="k-eventcard5-desig">Secretary, Dept. of Science<br/>& Tech, Gov. of India</p>
                        <p className="k-eventcard5-role k-new">-----</p>
                        <p className="k-eventcard5-desc">Ashutosh Sharma is a Secretary to the Government of India since January 2015, heading the …</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red">
                        <img src="./images/events/policy_series/innerPage/4-2.png"></img>
                        <div className="">
                        <h5>Dr. Chandrima Shaha</h5>
                        <p className="k-eventcard5-desig">President, Indian National<br/>
                        Science Academy</p>
                        <p className="k-eventcard5-role">-----</p>
                        <p className="k-eventcard5-desc">Prof. Chandrima Shaha is a biologist and the first ever woman president of the Indian National…</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                </Row>
               
            </Container>
           </section>
           <section className="attendees">
            <Container>
                <h2>Attendees</h2>
                <p>Young Indians, passionate about bringing about positive change and building India</p>
                <p>Those aspiring to build a career in public life, public policy and administration, social sector, media, journalism and communications, politics, and other related fields</p>
                <p>Academicians, researchers and journalists working in or following the fields mentioned above</p>
                <p>Those watching the unfolding Afghanistan crisis closely – and are keen to hear from experts with relevant experience at the highest level</p>
            </Container>
           </section>
    </div>

    
    </>
    );
}
export default Augusttalkpolicy;