import { Col, Row, Container } from "react-bootstrap";

function BeyondQuotas(){
    return(
        <>
        <section>
            <Container>
                <Row className="blog_text">
                    <Col xl={12}>
                        <h6 className="mt-2">
                            OPTION
                        </h6>
                    <h5>Silenced Lives : The Persistence of Female Feticide in India</h5>
                    <img src="./images/blogs/blog_2.jpg"  alt="banner"/>
                   <div className="blog_name">
                   <img  src="./images/blogs/author/Srajan-Mishra.png"/>
                   <p>
                   By, <br/> Srajan Mishra – Student, Kautilya
                   </p>
                   </div>
                   
                    </Col>
                    <Col xl={12}>
                    <div >
<p >The topic of gender representation in politics is one that India, a country renowned for its vibrant and diverse democracy, has long debated. Women remain <a href="https://indianexpress.com/article/opinion/columns/in-politics-and-bureaucracy-women-are-severely-under-represented-8492805/" target="_blank" rel="noopener"><span >underrepresented</span></a> in the Indian Parliament despite the country’s <a href="https://censusindia.gov.in/census.website/data/population-finder" target="_blank" rel="noopener"><span >sizable female population</span></a>.In this blog article, we will explore the complex implications of women’s reservations in Indian politics. Staring it from the historical perspective and comparing it with the current situation.</p>
<p >Women have <a href="https://www.civilsdaily.com/news/political-representation-of-women-in-india/" target="_blank" rel="noopener"><span >historically been barred</span></a> from positions of power in all walks of life, including politics, which is very strange because Congress, being the oldest political party in India, had its <a href="https://www.outlookindia.com/national/mapping-women-s-political-journey-magazine-321363" target="_blank" rel="noopener"><span >first women president Annie Besant way back in 1917</span></a>; it was way before the United Kingdom allowed women suffrage, that too in a limited basis; and also, the <a href="https://www.outlookindia.com/national/mapping-women-s-political-journey-magazine-321363" target="_blank" rel="noopener"><span >Madras and Bombay legislative assemblies granted voting rights to the women in 1921</span></a>; after that, in 1923, in the United Provinces, way before many women of European countries could imagine. India has seen a woman Prime Minister as early as 1966, Mrs. Indira Gandhi, and two heads of state, i.e. Mrs. Pratibha Patil and the current serving Mrs. Draupadi Murmu. But after all this, there have been very few cases in which women have been the chief ministers of the state. After having the women PM early as an achievement, <a href="https://www.outlookindia.com/national/mapping-women-s-political-journey-magazine-321363" target="_blank" rel="noopener"><span >only 13 women were given posts as ministers in the first two decades. The 299-member constituent assembly had only 15 women members,</span></a> which is still considerable because India Just became independent and had high illiteracy rates. But what about now?</p>
<p ><img data-src="./images/blogs/parliment.png " class=" ls-is-cached lazyloaded" src="https://kspp.edu.in/wp-content/uploads/2023/12/parliment.png" data-no-retina="" /><noscript><img src="https://kspp.edu.in/wp-content/uploads/2023/12/parliment.png" /></noscript></p>
<p >The efforts for the political empowerment of women <a href="https://wcd.nic.in/sites/default/files/ncwact.pdf" target="_blank" rel="noopener"><span >started in the 1990s</span></a>, the series of steps we can say has an important role in this such as the <a href="http://ncw.nic.in/commission/about-us" target="_blank" rel="noopener"><span >National Commission for Women was set up in 1990</span></a> and the then Prime Minister PV Narasimha Rao had <a href="https://cabsec.gov.in/writereaddata/councilofministers/english/1_Upload_2122.pdf" target="_blank" rel="noopener"><span >7 women ministers in the government,</span></a></p>
<p >which the PM Indira Gandhi also failed to achieve even being a female. <a href="https://indianexpress.com/article/explained/explained-law/73-74-amendment-reservation-women-elected-bodies-8947255/" target="_blank" rel="noopener"><span >The passing of the 73rd and 74th Constitutional Amendment Acts provided 33% reservation</span></a> for females in the Panchayati Raj institutions. This gave hope for <a href="https://indianexpress.com/article/explained/explained-law/73-74-amendment-reservation-women-elected-bodies-8947255/" target="_blank" rel="noopener"><span >33% reservation in the Lok Sabha.</span></a> But unfortunately, it did not get passed and went to the select committee.</p>
<p >The efforts took 27 years in the making as the debate started long ago, as we have discussed earlier. The bill to introduce the <a href="https://www.scobserver.in/journal/the-womens-reservation-bill-has-a-long-history-of-arguments-and-stakes/" target="_blank" rel="noopener"><span >33% reservation in the parliament and the state introduced in 1996</span></a> by the central government, which was headed by the then-prime minister, HD Deve Gowda. It was reintroduced in the parliament many times in 1998, 1999, and again in 2018.</p>
<p >The current bill tabled by the current BJP government has a long history, and that is the reason Congress is also claiming credit for that bill.</p>
<p >As the new bill is passed in both houses of the parliament and is waiting for the president’s assent, let’s talk about the bill and see if it can uplift the position of women in Indian society. The new bill is tabled as the <a href="https://prsindia.org/files/bills_acts/bills_parliament/2023/Constitution_(128th_Amendment)_Bill_2023.pdf" target="_blank" rel="noopener"><span >“Nari Shakti Vandan Adhiniyam 2023”</span></a>. It got passed by 454 of the 456 MPs present. The bill has been termed as ‘historic’ by the government. But let’s see if that is so. The bill demands 33% reservation for women in the parliament and state assemblies. This bill will significantly increase the participation of women in the political sphere. Currently, only <a href="https://thewire.in/government/in-charts-what-womens-representation-in-indias-parliament-assemblies-looks-like-now" target="_blank" rel="noopener"><span >15.2 percent of seats in Lok Sabha and 13.9 percent of seats in Rajya Sabha are held by women.</span></a> This is much higher than that of the <a href="https://www.bbc.com/news/world-asia-india-66878565" target="_blank" rel="noopener"><span >first Lok Sabha in 1952, which had only 5% female MPs and is also much lower than the current 33% percent reservation</span></a>. India’s rank is also poor compared to other countries. India ranks <a href="https://data.ipu.org/women-ranking?month=10&amp;year=2023" target="_blank" rel="noopener"><span >142</span></a> far below its neighbors Nepal and Pakistan, according to the <a href="https://www.ipu.org/resources/publications/about-ipu/2023-03/impact-report-2022" target="_blank" rel="noopener"><span >Inter-Parliamentary Union Report</span></a>.</p>
<p >This bill will increase women’s empowerment, most famously called Nari Shakti. It will reserve nearly 180 seats out of 545 in Lok Sabha. This will be enforced for 15 years but can be extended by the parliament. But the bigger question is that in a country with a female population nearly equal to that of men, is 33% reservation a good choice? Yes, it is a good start but it should be increased to 50%. The reservation will be given on a rotational basis; the issue is not rotation but when the seat is reserved for the women, is the average women getting the chance to fight the election or it is the women from already established political families taking the seat. It is said that this act will give justice to women and women-related issues because a woman in that seat really can work for it, but the women getting elected are bound by their party whip, whether the law is against women, they will not be able to raise or protest about it. But when can we see more women politicians in the parliament? The new act will only apply after the delimitation exercise which is long due, due to pending census which is not going to happen anytime soon. But is delimitation needed in the first place? Election after the census and delimitation will give the chance to the women to elect. So there is only after 2029 to see women in the parliament.</p>
<p >So yes, introducing and getting this bill with the right motive is an applauding step. The major role will be of political parties who must encourage women to fight the elections more. Also, the political parties should do some basic reforms in the internal structure so that more women are in public life. Moreover, apart from the bill, the initiatives like lowering the barrier for women to enter in public life will positively affect the situation on the ground. . The long pending seed has been sown. However, reaping the benefits of the matured fruit will take a while. If all the barriers are tackled, then this bill will be fruitful for the Indian Democracy.</p>
</div>
                  </Col>
                </Row>
            </Container>
        </section>
       
        </>
    );
}
export default BeyondQuotas;