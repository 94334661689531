import { Col, Container, Row } from "react-bootstrap";

function PoliticalCommunicationExpert(){
    return(
        <>
         <Container>
            <Row>
                <Col xl={12}>
        <p style={{ textAlign: "right", marginTop: "60px" }}>
                 <a style={{ backgroundColor: "black", padding: "10px", color: "white" }}>INVITE US TO A COLLEGE WEBINAR</a>
         
            </p>
            </Col>
            </Row>
        </Container>
        <section className="politicalCE">
        <Container>
            <Row>
                <Col xl={12}>
               
			<h4 class="text-white"><span style={{backgroundColor: "#b1040e", padding: "5px" ,fontSize: "22px"}}>Ask Me Anything</span></h4>
<h4 class="text-dark bold" style={{fontSize:"34px"}}>Careers in Public Policy:</h4>
<h4 class="text-primary" style={{fontSize: "36px", fontWeight: "bolder"}}>Growing demand for policy 
& <br /> political  communication experts</h4>
<p>
<img src="./images/events/Calender_time.png"  style={{width: "40px"}}/>
<span>25th June, 2022
11 am – 12 pm</span></p>

		
                </Col>
            </Row>
        </Container>
        </section>
        <div className="bg-primary p-5">
            <Container>
<Row>
    <Col xl={12}>
      <div>
        <p className="text-white">
        From migration to climate change, poverty to cybersecurity, governments,
         businesses and civil societies have begun to understand that following
          traditional mechanisms does 
        not seem to bring the development that will solve the economic problems of tomorrow.
        </p>
<p className="text-white">
If you want to solve these 21st-century challenges and in the process build an impactful career in the Government, corporations and Public services sector, a career in public policy can be as promising and dynamic as it sounds. Know all the heights that
 you can reach in public policy in our upcoming session on Careers in Public Policy.
</p>
      </div>
    </Col>
</Row>
            </Container>
        </div>

        <section>
            <Container>
                <Row>
                    <Col xl={12}>
                    <img src="./images/events/policy_series/innerPage/politicalCommunication/image1.jpg" />
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                    <img src="./images/events/policy_series/innerPage/politicalCommunication/image2.jpg" />
                    </Col>
                    <Col xl={6}>
                    <img src="./images/events/policy_series/innerPage/politicalCommunication/image3.jpg" />
                    </Col>
                    <Col xl={6}>
                    <img src="./images/events/policy_series/innerPage/politicalCommunication/image4.jpg" />
                    </Col>
                    <Col xl={6}>
                    <img src="./images/events/policy_series/innerPage/politicalCommunication/image5.jpg" />
                    </Col>
                </Row>
            </Container>
        </section>

       <div className="bg-primary p-5">
        <Container>
            <Row>
                <Col xl={12}>
                <h4 className="text-white text-center"> </h4>
        <ul className="entry_content text-white">
<li>Those aspiring to build a career in public life, public policy and administration, governance, media, journalism and communications, social sector and other related fields.</li>
<li>Academicians, researchers, journalists and professionals, analysts, working in or following the fields mentioned above.</li>
<li>Young Indians, passionate about bringing about change in India.</li>
</ul>

</Col>
            </Row>
        </Container>       
       
       </div>

       <Container>
            <Row>
                <Col xl={12} className="p-4">
<h5 className="text-primary text-center " style={{fontSize: "25px", fontWeight: "700", padding: "10px"}}>
ARE YOU A CRITICAL THINKER AIMING TO BE A CREATIVE LEADER? <br/>
GRAB YOUR SEAT 
</h5>
                </Col>
            </Row>
        </Container>
        </>
    );
}
export default PoliticalCommunicationExpert;