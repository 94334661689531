import { BrowserRouter as Router } from "react-router-dom";
import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.scss";
import "./assets/css/responsive.css";
import NavBar from "./components/Navbar";
import Footer from "./components/Common_footer";

import "./assets/css/responsive.css";
import "./assets/css/style.scss";
import SocialLinks from "./components/SocialLinks";
import BackToTopButton from "./components/Backtotop";
import Metatag from "./components/Metatag";
import Routers from "./Routers";

function App() {
  const location_path = window.location.pathname;  
  return (
    <Router>
      <>
        {location_path == "/governance-contact" ? "" : <NavBar />}
        {/* <NavBar /> */}

        {location_path == "/governance-contact" ? "" : <Metatag />}

        <Routers />
        {location_path == "/governance-contact" ? "" : <SocialLinks />}
        {/* <SocialLinks /> */}
        {location_path == "/governance-contact" ? (
          ""
        ) : (
          <BackToTopButton />
        )}
        {/* <Admission_enquiry /> */}
        {/* <Footer /> */}
        {location_path == "/governance-contact" ? "" : <Footer />}
      </>
    </Router>
  );
}

export default App;