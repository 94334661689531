import { Col, Container, Row } from "react-bootstrap";

function NuclearDealToTech(){
    return(<>
    <div className="nuclearDealtoTech">
    <section>
            <Container>
                
                <Row>
                <Col xl={12} className="m-4 p-4"></Col>
                    <Col xl={12} className="mb-5">
                    <p class="LTP-txt">Let’s Talk
                      <span className="bold mb-5">Policy</span></p>
                    </Col>
                    <Col xl={12}>
                 
                    

			<p class="LTP-tit">From Nuclear Deal<br /> to Critical Tech </p>
            <p style={{fontWeight: "400", fontSize: "24px", color: "white"}}>The New Chapter in India-US Ties </p>
                    <p class="ltp-event mt-5">
                        <span class="LTP eventicon">
                            <img width="2%" src="./images/events/calender_white.png"  className="me-2" />
                                <span style={{color: '#fff', fontWeight: '600'}}>July 27, Thursday</span><br />
                    <img width="2%"   src="./images/events/time_white.png" className="me-2" />
                    <span style={{color: '#fff', fontWeight: '600'}}>7:00 PM – 8:00 PM IST 
9:30 AM – 10:30 AM EST <br/>
(via Zoom)</span></span></p>

                      
                        
                    </Col>
                </Row>
            </Container>
           </section>
       
    </div>

    <section>
        <Container>
        <Row className="wpb_single_image">
                <Col xl={4}>
      <img src="./images/events/policy_series/innerPage/nuclearToTech/Lisa.png" />
      </Col>
      <Col xl={4}>
      <img src="./images/events/policy_series/innerPage/nuclearToTech/Navtej.png" />
                </Col>
                <Col xl={4}>
      <img src="./images/events/policy_series/innerPage/nuclearToTech/Smita.png" />
                </Col>
            </Row>
        </Container>
    </section>


    <section className="nuclearDealtoTech2">
    <Container>
    <Row>
        <Col xl={12}>
            
        <h4 className="eventsubtitle-lineheight2  txt-white"><strong>DECODING THE US-INDIA PARADIGM</strong></h4>
      <div class="heading_border_white mb-4"> </div>
        <p className="text-white">
        Fortified ties between New Delhi and Washington were the centerpiece of Prime Minister Narendra Modi’s three-day visit to the US last month.
         Both governments resolved long-standing trade disputes and forged new agreements in defense cooperation,
         technology sharing, and mineral sharing.</p> 
          <p className="text-white">
          What remained off the table during PM Modi’s high-profile addresses was a defined stance on the Russia-Ukraine war and the acrimony with China.
           India’s bid to become the “factory of the world” did get some space in the dialogues in the context of semiconductor processing and packaging, but the tag remains a far cry.
           Decode the implications of these developments with our expert panel.
               </p>
        </Col>
    </Row>
</Container>
    </section>


    <section>
        <Container>
            <Row>
                <Col xl={12}>
                <h4 className="pseudo_border">WHAT WILL BE COVERED</h4>
                        <div className="heading_border mb-4"> </div>

                </Col>
                <Col xl={12}>
                <ul className="entry_content">
                <li>What does the resolution of key trade disputes mean for India’s exports?</li>
                <li>To what extent do the technology-sharing agreements aid India’s vision as a global manufacturer?</li>
                <li>What’s the road ahead for joint nuclear projects that have been stalled for over a decade?</li>
                <li>PM Modi’s visit did not include any official talks on the stalled FTA negotiations. What are the policy roadmaps that can help break the deadlock?</li>
                <li>What is the mineral sharing partnership and what action is required on India’s part?</li>
                <li>To what extent can the ICET partnership aid India’s technical capabilities?</li>
                    </ul>
                </Col>
            </Row>
        </Container>
     </section>

<section className="section_bg">
    <Container>
        <Row>
        <Col xl={12}>
                <h4 className="pseudo_border">WHO SHOULD ATTEND?</h4>
                        <div className="heading_border mb-4"> </div>

                </Col>
                <Col xl={12}>
                <ul className="entry_content">
                                    
                <li>Graduates and students in the final year of their program</li>
<li>Members of think tanks involved in global trade, research, and foreign policy</li>
<li>Civil services aspirants</li>
<li>Members of domestic and global trade organizations and industry associations</li>
<li>Students of international relations</li>

                </ul>
                </Col>
        </Row>
    </Container>
</section>
    </>
    );
}
export default NuclearDealToTech;