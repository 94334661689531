import { Col, Container, Row } from "react-bootstrap";
function AreCryptoCurrencyViable(){
    return(
        <>
        <section className="cryptoViable">
         <Container className="mt-5">
          <Row>
            <Col xl={12} className="mt-5">
            <h2 class="text-white text-start mt-5" style={{fontSize: "40px" ,fontWeight: "800" ,  lineHeight: "50px"}}>Let’s Talk<br />
                Policy</h2>
                <h4 class="text-white" style={{fontSize: "24px", fontWeight: "600", lineHeight:"36px"}} >ARE CRYPTO<br />
                CURRENCIES VIABLE ?</h4>
                <p class="text-white">December 14th, 2021 | 6PM onwards</p>
            </Col>
         </Row>
        </Container>
        </section>  

        <section className="bg-primary">
        <Container>
          <Row>
            <Col xl={12}>
                <h3 className="text-white">
                About the penalists
                </h3>
            </Col>
            <Col xl={6} className="mx-auto">
            <Row>
                <Col xl={6}>
                        <img src="images/events/policy_series/innerPage/crypto/Nidhi.png" />
                        <h6 className="text-white text-center">
                        EVENT MODERATED BY  </h6>       
                        <p className="text-white text-center"> Nidhi Razdan <br /> 
                        <small>Visiting Faculty, Kautilya</small>
                        </p>                
                </Col>
                <Col xl={6}>
                    <img src="images/events/policy_series/innerPage/crypto/Milind.png" />
                    <h6 className="text-white text-center"> Milind Deora </h6>       
                        <p className="text-white text-center">  
                        <small>Former Union Minister
                            of State for Communications &
                            Information Technology &
                            Shipping</small>
                        </p>                
                 </Col>
            </Row>
            <Row>
                <Col xl={6}>
                        <img src="images/events/policy_series/innerPage/crypto/Krishna.png" />
                        <h6 className="text-white text-center">
                        Krishna Hegde  </h6>       
                        <p className="text-white text-center">
                        <small>Senior Vice President & New Initiatives at CoinSwitch Kuber</small>
                        </p>                
                </Col>
                <Col xl={6}>
                    <img src="images/events/policy_series/innerPage/crypto/Rajat.png" />
                    <h6 className="text-white text-center"> Milind Deora </h6>       
                        <p className="text-white text-center">  
                        <small>Former Union Minister
                            of State for Communications &
                            Information Technology &
                            Shipping</small>
                        </p>                
                 </Col>
            </Row>


            </Col>
            </Row>
            </Container>
        </section>


        <section>
        <Container>
          <Row>
            <Col xl={12}>
               <h3 className="text-primary">About the event</h3>
             
			<p>The government of India is all set to introduce a new bill in this parliament session to regulate the use of cryptocurrencies. With more than 300 million cryptocurrency users worldwide, it has become the biggest buzzword globally. The widespread use and investments show terrific potential for a decentralized yet secure medium of exchange with untold implications for global finance. Different cryptocurrency exchanges offer various types of cryptocurrencies and have different terms, policies, payment methods, and fees. Exchanges also differ on aspects such as security, user-friendliness, functionality, and design.</p>
            <p>Critics however, argue that cryptocurrency is highly volatile, inconvenient, and designed to facilitate illegal conduct. It is depicted as a major threat to the current financial system. The lack of transparency and accountability can jinx the benefits of digital currencies and in different industries beyond finance. Talks over regulation of digital currencies and crypto exchanges are a way to help the market become healthier and more transparent. There is a lot of work to be done and use the combination of technology, knowledge and accountability to fight these flaws.</p>
            <p>What are the drawbacks of the so-called transparent cryptosphere, what does the future look like, and what shape should the new policies take? Join us for the Let’s Talk Policy session and hear from our panelists.</p>

		
            </Col>
           </Row>
        </Container>
        </section>
     
     
        <section className="bg-dark">
        <Container>
          <Row>
            <Col xl={12}>
               <h3 className="text-white">Lets talk Policy <small> is a panel discussion series initiated by Kautilya School of Public Policy,</small></h3>
             
			<p className="text-white">
            that brings forth a select group of panelists, sharing differing views in key national and global relevance, and building the dialogue into a conversation for the student community. The series aims to unravel major themes around democracy, politics and human rights, and explore the challenges and rewards of implementing practices. We aim to create a cohesive platform and call on thematic experts to foster a healthy dialogue around the most pressing themes.
            </p>
		
            </Col>
           </Row>
        </Container>
        </section>
     

     
        <section className="CareerInPP2 ">
            <Container>
            <Row>
        <Col xl={12}>
            
        <h4 className="eventsubtitle-lineheight2  txt-white"><strong>Who should attend ?</strong></h4>
        <ul class="entry_content text-white">
        <li>Those aspiring to build a career in public life, public policy and administration, governance, media, journalism and communications, politics, social sector and other related fields.</li>
<li>Academicians, researchers, journalists and professionals working in or following the fields mentioned above.</li>
<li>Young Indians, passionate about bringing about change and building India.</li>
             </ul>
      
           
        </Col>
    </Row>
            </Container>
          </section>

          <section>
          <Container>
            <Row>
        <Col xl={12}> 
        <h5 className="text-primary text-center">
        KNOW MORE ABOUT HOW TO STRUCTURE A CAREER IN THE FIELD OF PUBLIC POLICY
        </h5>
            </Col>
            </Row>
            </Container>
          </section>
     
        </>
    );
}
export default AreCryptoCurrencyViable;