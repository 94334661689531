import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EnquiryForm from '../pages/register';

import { useLocation } from 'react-router-dom';



const EnquiryModal = ({ facultyData }) => {

  const { pathname } = useLocation();


  const imagePath = 'https://guprojects.gitam.edu/kautilya-admin/public/ourteam/';
  const imagePathfaculty = 'https://guprojects.gitam.edu/kautilya-admin/public/faculty/';
  const almuniProfilePath = "https://guprojects.gitam.edu/kautilya-admin/public/alumni/";



  const pageUrl = window.location.pathname;
  // Split the URL path by '/'
  const parts = pageUrl.split('/');
  // Get the last part of the URL path after the last '/'
  const lastPart = parts[parts.length - 1];


  if (!facultyData) {
    return;
  }
 
  return (
    // facultyData.designation1, facultyData.place ---> this data is coming from the alumniNetwork data

    
    <div className='enquiry_modal_content'>
      {pathname === "/alumni-association" || pathname === "/paper-presentation" || pathname === "/simulation" || pathname === "/contact-us"
        ? <img src={facultyData.profile ? `${almuniProfilePath}${facultyData.profile}` : facultyData.imgURL} alt={facultyData.name} /> :
        <img src={lastPart == "our-faculty" ? `${imagePathfaculty}${facultyData.profile}` : `${imagePath}${facultyData.profile}`} alt={facultyData.name} />}

      <p className='faculty_name'>{facultyData.name}</p>
      <p className='faculty_designation'>{facultyData.designation1 ? facultyData.designation1 : facultyData.designation} {facultyData.place ? facultyData.place : facultyData.city} </p>

      <p dangerouslySetInnerHTML={{ __html: facultyData.description }}></p>

    </div>
  );
};

export default EnquiryModal;