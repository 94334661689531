import { useState, useEffect } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Tabs_section from "../components/Tabs";
import TabSection from "../components/TabSection";


import '../assets/css/navbar.css';


function Publications() {

  const bannerPath = "https://guprojects.gitam.edu/kautilya-admin/public/publications/"

  const [publicantsbanner, setPublicantsbanner] = useState([]);
  const [publicantsTabsData, setPublicantsTabsData] = useState([]);

  useEffect(() => {
    fetchpublicantsbanner();
    fetchpublicantsTabsData();
  }, []);
  const fetchpublicantsbanner = async () => {
    try {
      const response = await axios.get('https://guprojects.gitam.edu/kautilya-admin/api/fetch-publication-slider-data'); // Replace with your actual API endpoint
      setPublicantsbanner(response.data.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchpublicantsTabsData = async () => {
    try {
      const response = await axios.get('https://guprojects.gitam.edu/kautilya-admin/api/fetch-publication-tabs-data'); // Replace with your actual API endpoint
      setPublicantsTabsData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // console.log("publication Tabs", publicantsTabsData);

  const facultyTabs = publicantsTabsData?.filter((each) => each.category === "Publications page");



  return (
    <>
      <section className="phd">
        <Container>
          <div className="mt-3 mb-5">


            <picture>

              <source media="(max-width:620px)" srcSet={`${bannerPath}${publicantsbanner.mobile_banner}`} />
              <source media="(min-width:621px)" srcSet={`${bannerPath}${publicantsbanner.desktop_banner}`} />
              <img
                className="d-block_carousel"
                src={`${bannerPath}${publicantsbanner.desktop_banner}`}
                alt={`Slide ${publicantsbanner.category}`}
              />
            </picture>

          </div>

        </Container>

        <TabSection tabsData={facultyTabs} />
      </section>
    </>
  );
}
export default Publications;