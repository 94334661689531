
function StudentClubSliderimage(props) {
    return (
      <div className="studentClubSlider">

     
     
      <img variant="top" src={ props.imgURL } alt='student' />
        
      </div>
    );
  }
  
  export default StudentClubSliderimage;