import { Col, Container, Row } from "react-bootstrap";
function Septembertalkpolicy(){
    return(<>
    <div className="policy_talk">
    <section className="lets_talk">
            <Container>
                
                <Row className="mt-5">
               
                    <Col xl={6} className="mt-5">

                    <p class="eventoutline">PANEL DISCUSSION</p>
                    <div className="d-flex panel">
                    <span><img src="./images/events/policy_series/innerPage/quote.png"></img></span>
                    <p class="LTP-txt">Let’s Talk <br/>
                      <span className="bold mb-5">Policy</span></p>
                    </div>
                        <p class="LTP-tit">“India’s Economic Recovery Roadmap”</p>
                        <p class="LTP-tit">In association with  <span><img src="./images/events/policy_series/innerPage/Ficci-Logo.png"></img></span></p>
                        <div className="d-flex panel_cal">
                           <span><img src="./images/events/policy_series/innerPage/evenicon1.png"></img><span class="LTP-tit">Sep 18, 2021</span></span> 
                            <span><img src="./images/events/policy_series/innerPage/evenicon2.png"></img><span class="LTP-tit">6 PM IST</span></span>
                        </div>
                    </Col>
                    <Col xl={6} className="mt-5">
                     <img src="./images/events/policy_series/innerPage/Banner.png"></img>
                    </Col>
                </Row>

            </Container>
           </section>
           <section className="about_evnt">
            <Container>
               <h2>About the event</h2>
               <p>The pandemic has left the national economy and businesses counting the costs as governments struggled with lockdown measures. India’s economy showed signs of recovery in July as manufacturing rebounded with the rise in domestic and international demand, prompting companies to create new jobs.</p>
               <p>There is more good news in the offing, with exports reportedly witnessing a healthy rise as orders grew at the fastest rate since April, and employment has also increased, with the services sector providing 8.5 million additional jobs in August. While the country’s economy is recovering, rising inflation remains a major hurdle. A recent poll conducted by Reuters signals a further rise in inflation due to higher fuel prices.</p>
               <p><b>With the current spike in COVID19+ cases in a few states, what turn will India’s economy take?</b></p>
               <p>Hear all about the economic roadmap and recovery from our expert panellists in this <b>Let’s Talk Policy </b> session – <b>Naina Lal Kidwai</b>, Former Head, HSBC, <b>Sangita Reddy</b>, Joint Managing Director, Apollo Hospital Group, and <b>Dilip Chenoy</b>, Secretary-General, FICCI</p>
               <p>The event was moderated by renowned journalist, <b>Nidhi Razdan.</b></p>
               </Container>
           </section>
           <section className="yellow_bg">
            <Container>
               <p><span>‘Let’s Talk Policy’</span> <b>is a panel discussion series initiated by Kautilya School of Public Policy</b>, that brings forth a select group of panellists, sharing differing views on key issues of national and global relevance, and building the dialogue into a conversation for the student community.</p>
               <p>The series aims to unravel major themes around democracy, politics, and human rights, and explore the challenges and rewards of implementing practices. We aim to create a cohesive platform and call on thematic experts to foster a healthy dialogue around the most-pressing issues.</p>
               </Container>
           </section>
           <section className="panellists">
            <Container>
                <h2>About the panellists</h2>
                <Row>
                    <Col xl={4} className="bg_red">
                        <img src="./images/events/policy_series/innerPage/Nidi.png"></img>
                        <div className="">
                        <h5>Nidhi Razdan</h5>
                        <p className="k-eventcard5-desig">Visiting Faculty, Kautilya &<br/>
                        Fmr. Exec. Editor NDTV</p>
                        <p className="k-eventcard5-role">Moderator</p>
                        <p className="k-eventcard5-desc">Nidhi Razdan (Moderator) is an award-winning journalist, author and the former Executive Editor of…</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red bg_black">
                        <img src="./images/events/policy_series/innerPage/Dilip-Chenoy_circle.png"></img>
                        <div className="">
                        <h5>Dilip Chenoy</h5>
                        <p className="k-eventcard5-desig">Secretary General,<br/>FICCI</p>
                        <p className="k-eventcard5-role k-new">-------</p>
                        <p className="k-eventcard5-desc">Dilip Chenoy leads the permanent secretariat of FICCI, which is the largest and oldest apex …</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>

                    <Col xl={4} className="bg_red bg_black">
                        <img src="./images/events/policy_series/innerPage/Dr.-Sangita-Redy_circle.png"></img>
                        <div className="">
                        <h5>Dr. Sangita Reddy</h5>
                        <p className="k-eventcard5-desig">Joint Managing Director,<br/>Apollo Hospitals</p>
                        <p className="k-eventcard5-role k-new">-----</p>
                        <p className="k-eventcard5-desc">Dr. Sangita Reddy is a Global Healthcare influencer and likes to be known as a healthcare…</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red">
                        <img src="./images/events/policy_series/innerPage/Naina-Lal-Kidwai.png"></img>
                        <div className="">
                        <h5>Naina Lal Kidwai</h5>
                        <p className="k-eventcard5-desig">Former Head, HSBC</p>
                        <p className="k-eventcard5-role">-----</p>
                        <p className="k-eventcard5-desc">Naina Lal Kidwai is an Indian banker, chartered accountant and business executive. She was…</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                </Row>
               
            </Container>
           </section>
           <section className="attendees">
            <Container>
                <h2>Attendees</h2>
                <p>Young Indians, passionate about bringing about positive change and building India</p>
                <p>Those aspiring to build a career in public life, public policy and administration, social sector, media, journalism and communications, politics, and other related fields</p>
                <p>Academicians, researchers and journalists working in or following the fields mentioned above</p>
                <p>Those watching the unfolding Afghanistan crisis closely – and are keen to hear from experts with relevant experience at the highest level</p>
            </Container>
           </section>
    </div>

    
    </>
    );
}
export default Septembertalkpolicy;