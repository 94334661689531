import { Container, Row, Col } from "react-bootstrap";

function AboutConclave(){
    return(
        <>
        <div class="financial-ad">
           
         </div>
         <div class="container aboutconclave">
                <div class="row">
                    <div class="col-xl-12">
                      <img  src="./images/events/Colluquy/banner.jpg"/>
                        </div>
                    </div>
         </div>
               <Container className="mt-5">
                <Row>
                    <Col xl={12}>
                    <div class="headingWithBG">
                        <h4>Aim</h4>
                        </div>
                        <p>The objective of the policy event is to offer a platform for public policy professionals to come together, interact, and engage in collective learning.</p>
                  
                    </Col>
                    <Col xl={12}>
                    <div class="headingWithBG">
                        <h4>Objective</h4>
                        </div>
                        <p>
                       <b> Paper Presentation–</b>  Public policy is a significant tool for growth for any country.
                         Especially when it comes to aiming for equity in growth for a developing country like India.
                          Thus, the purpose of the paper presentation is to invite public policy professionals in our country to share their ideas regarding policy initiatives that can foster equitable growth in India. This encompasses various areas- Government and Business; Human Rights, Law, and Democracy; 
                        Economics for Development; Governance and Society; and International Relations.
                        </p>
                        <p>
                       <b> Simulation–</b>  Climate change stands as the foremost and most critical challenge confronting the world in the present era. 
                        With the potential to have far-reaching, intersectional consequences which can have a devastating impact on life on Earth.
                         Our international institutions have thus far failed to bring forth very fruitful results. 
                         The objective of the Simulation is to facilitate interaction among public policy professionals, 
                         allowing them to delve into the intricacies of international negotiations.
                         Through this process, we aim to collectively explore potential solutions to address the global threat of climate change.
                        </p>
                    </Col>
                    <Col xl={12}>
                    <div class="headingWithBG">
                        <h4>Message from the Core Committee</h4>
                        </div>
                        <div class="wpb_wrapper">
			<p >India stands at a critical juncture in its history. It has reached the perch of geopolitical importance. The United States of America, once known for imposing sanctions in response to India’s nuclear tests in 1998, now actively collaborates with all levels of the Indian government, forging a formalized alliance. India has emerged as a prominent figure in the “Global South,” with neighboring South Asian nations and even African countries turning to India for support in advancing their interests on the global stage.</p>
<p>Today, India possesses a formidable platform to redefine global norms and practices. With expanding export markets, rapidly urbanizing metropolitan areas, and highly competitive Indian professionals making their mark worldwide, India is experiencing significant success. However, it is important to acknowledge that along with these remarkable achievements, there are also profound challenges and setbacks that need to be addressed. As per a report from Oxfam, the top 10% of the Indian population holds 77% of the total national wealth. Our state’s capacity when it comes to resources and manpower to attend to the diverse needs of the country is highly limited. With different political, social, and economic dynamics in every state and Union Territory, the need of the hour is to decentralize policy formulation and implementation.</p>
<p>This is exactly what we do in the classrooms of Kautilya School of Public Policy. Coming from highly diverse backgrounds, we are exposed to the development sector veterans (IAS officers, ThinkTanks, former ambassadors, academicians, practitioners) across various sectors. As students, we analyze and interpret this wealth of information through our own individual lenses. The ensuing discussions and debates from this exchange of ideas are truly stimulating and energizing.</p>
<p>Our aspiration for this conference is to replicate the dynamics of our classroom on a larger scale. We truly believe that to make our democracy great, we need more of our country’s minds working on the ‘wicked problems’ of this country and sharing insights with each other. And while we are sharing insights, there is a possibility that you might unlock a personal or an intellectual roadblock of your professional journey. We are incredibly excited to be a part of that transformative experience.</p>
<p>We extend our hopes that you thoroughly enjoy the event and eagerly anticipate your presence at Kautilya!</p>
<p>Yours Sincerely,<br />
Core Committee</p>

		</div>
                        </Col>
                </Row>
               </Container>
        </>
    );
}
export default AboutConclave;