import { Col, Container, Row } from "react-bootstrap";
function EconomyInflation(){
    return(
        <>
      
           
            <p style={{ textAlign: "right", marginTop: "60px" }}>
                 <a style={{ backgroundColor: "black", padding: "10px", color: "white" }}>INVITE US TO A COLLEGE WEBINAR</a>
         
            </p>
                
          <section className="economyInflation">
        <Container>
                <Row>
                    <Col xl={12} className="m-3 p-3"></Col>
                    <Col xl={12}>
                    <div class="top-div">
                        <img src="./images/events/policy_series/innerPage/crypto/quote.png" style={{width:"100px"}} />
                      
                    <h4 class="cus-public text-white"><strong>Let’s Talk Policy</strong></h4>
                    <h4 class="k-h2 eventsubtitle-lineheighttit text-white">Decrypting Crypto- How<br/>
                        Should India Regulate<br/>
                        Cryptocurrency?
                    </h4>
                    <h4 class="cus-time text-white">07:00 pm – 8:00 pm IST | November 21st</h4>
                    </div>
                   
                    </Col>
                </Row>
            </Container>
        </section>
        <section>
            <Container>
                <Row>
                    <Col xl={12}>
                        <p className="text-center">
                        India’s economic growth slowed to the lowest in a year in the first three months of
                         2022, hit by weakening consumer demand amid soaring prices that could make 
                         the central bank’s task of taming inflation without harming growth more difficult.
                          RBI has raised its inflation forecast to 6.7 per cent for the financial year 
                          2022-23 from an earlierestimate of 5.7 per cent. Consumer Price Index (CPI)
                           based inflation, which the RBI factors in while arriving at its monetary policy,
                            galloped for the seventh straight month to touch an 8-year high of 7.79 per cent
                             in April. The RBI raised the interest rate by 50 basis points to a two-year
                              high of 4.9 per cent 
                        as it doubled down to tame inflation that has surged in the last couple of months.
                        </p>
                        <p className="text-center">
                        Let’s talk policy is a series of discussions by Kautilya School of Public Policy,
                         that brings together speakers and experts from various fields. 
                         Here they share insights on national and global issues 
                         and build a conversation for the student community.
                          Up for discussion are major themes around democracy, politics and human 
                        rights Up fordiscussion are major themes around democracy, politics and human rights
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
        <section>
            <Container>
                <Row>
                    <Col xl={12}>
                    <img src="././images/events/policy_series/innerPage/economyInflation/1.jpg" />
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="economyInflation2 p-5">
            <Container>
                <Row>
                <Col xl={4}></Col>
                    <Col xl={8}>
                        <div className="ms-auto">

                      
                    <h4 className="pseudo_border">What will be discussed?</h4> 
                    <ul className="entry_content">
                    <li>Legislation regarding the prevention of stagflation</li>
                    <li>Frameworks for steps to be taken by the RBI to tackle inflation</li>
                    <li>Impact of the Russia-Ukraine war on the economy</li>
                    <li>The card tokenisation policy that is to be implemented</li>
                    <li>Monitoring bodies to keep track of the economy and its state on a day-to-day basis</li>
                    <li>Public initiatives and concerns about the falling cryptocurrency markets</li>
                                        </ul>
                                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
        <section>
        <Container>
                <Row>
               
                    <Col xl={12}>
                        <div className="ms-auto">

                      
                    <h4 className="pseudo_border">What will be discussed?</h4> 
                    <ul className="entry_content">
                    <li>Young Indians who are committed to bringing about change and building India</li>
<li>Those aspiring to build a career in public policy, public administration, social sector, journalism mass communications, and other related fields.</li>
<li>Academicians, researchers, journalists, professionals working in or following the fields mentioned above.</li>
<li>Economists, investors, business owners and everyone wishing to get a grip on the current scenario of the economy and the new policies that are to be implemented.</li>
                                          </ul>
                                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        </>
    );
}
export default EconomyInflation;