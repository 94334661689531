import { useState, useEffect } from 'react';
import axios from 'axios';
import { Col, Row, Container } from "react-bootstrap";
import Faculty from "../components/Faculty";
import Tabs_section from "../components/Tabs";

import '../assets/css/navbar.css';
import MentorComp from '../components/MentorComponent';
import TabSection from '../components/TabSection';



function Mentorship() {

    const [tabsMentorsdata, setTabsMentorsdata] = useState([]);
    const [mentorsData, setMentorsData] = useState([]);

    useEffect(() => {
        fetchtabsMentorsdata();
        fetchMentorsdata();
    }, []);
    const fetchtabsMentorsdata = async () => {
        try {
            const response = await axios.get('https://guprojects.gitam.edu/kautilya-admin/api/fetch-mentors-tabs-data'); // Replace with your actual API endpoint
            setTabsMentorsdata(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // console.log("tabs", tabsMentorsdata)

    const fetchMentorsdata = async () => {
        try {
            const response = await axios.get('https://guprojects.gitam.edu/kautilya-admin/api/fetch-mentorslist'); // Replace with your actual API endpoint
            setMentorsData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // console.log("mentors", mentorsData)


    return (
        <>
            <section className="phd">

                <Container>
                    <img src="./images/mentorship-program/banner.jpg" alt='banner' className='mt-3'></img>
                    <p className="mt-5 mb-5 mentorship-text">The Mentorship Scheme at Kautilya is envisaged to be one of the key pillars of the Master of Public Policy (MPP) program. Through this program, students are <b>connected to mentors with strong personal, academic, and professional track records</b>  who can positively impact and guide their journeys during and beyond MPP.
                        The Mentorship Programme is an interactive engagement between the mentors and mentees without being prescriptive.</p>
                    <h4 className='mt-5 mb-4 mentorship-heading'>Program Scheme</h4>
                    <p className='mb-5 mentorship-text'>The mentorship scheme starts in <b>August (1st Trimester)</b> once students have made their initial adjustments and have a better understanding of the academic schedule and the options available to them. The selection and screening process starts in the <b>3rd Week of August.</b>
                        The Mentorship schemecCoordinator will be the Point of Contact (POC) for the students and Mentors.</p>
                </Container>


            </section>
            <section className="section_bg">
                <Container>
                    <Row>
                        <Col xl={12} className="mt-4">
                            <a href='#' className='btn btn-secondary text-dark'> <i className='fa fa-download'></i> Know more </a>
                        </Col>
                        <Col xl={12} className="mt-4">
                            <TabSection tabsData={tabsMentorsdata} />
                        </Col>

                    </Row>
                </Container>
            </section>
            <section className='f-mentors'>
                <Container>
                    <h4 className='text-center mb-5'>Mentors List</h4>
                </Container>

                <MentorComp faculty={mentorsData} />

            </section>
        </>
    );
}
export default Mentorship;