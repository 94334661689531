import { Col, Container, Row } from "react-bootstrap";
function DataProtectionBill (){
    return(
        <>

<section className="dataProtection">
            <Container>
                <Row>
                    <Col xl={12} className="m-3 p-3"></Col>
                    <Col xl={12}>
                    <div class="top-div">
<h4 class="cus-public "><strong>Let’s Talk Policy</strong></h4>
<h4 class="k-h2 eventsubtitle-lineheighttit text-dark">Data Protection<br />
Bill 2022<br />
Unpacking the<br />
Revised Law</h4>
<h4 class="cus-time text-dark">07:00 pm – 8:00 pm IST<br />
December 21, 2022</h4>
</div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section>
            <Container className="wpb_single_image ">
                <Row>
                    <Col xl={3}>
                       <img src="./images/events/policy_series/innerPage/dataProtection/1.png" />
                    </Col>
                    <Col xl={3}>
                       <img src="./images/events/policy_series/innerPage/dataProtection/2.png" />
                    </Col>
                    <Col xl={3}>
                       <img src="./images/events/policy_series/innerPage/dataProtection/3.png" />
                    </Col>
                    <Col xl={3}>
                       <img src="./images/events/policy_series/innerPage/dataProtection/4.png" />
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="dataProtection2">
<Container>
    <Row>
        <Col xl={12}>
           <div className="m-5">
        <h4 className="eventsubtitle-lineheight2  txt-white"><strong>The Data Protection Bill 2022 – An Overview</strong></h4>
        <div class="heading_border_white mb-4"> </div>
        <p className="text-white">
        The draft Digital Personal Data Protection Bill 2022 was introduced after the withdrawal of the Personal Data Protection Bill, 2019. The draft Bill sets out the rights and duties of the citizen and the obligations of the Data Fiduciary to use the collected data lawfully.


       </p>
        
         <p className="text-white">
         There are debates on whether this bill is an improvement 
         over its previous avatar or if it is attempting to balance national security with global diplomacy and cross-border co-operation.
          </p> </div> 
        </Col>
    </Row>
</Container>
</section>

<section>
        <Container>
            <Row>
                <Col xl={12}>
                <h4 className="pseudo_border">What will be discussed ?</h4>
                        <div className="heading_border mb-4"> </div>

                </Col>
                <Col xl={12}>
                    <p>
                    In this episode of Let’s Talk Policy,
                     our panel experts will discuss and answer questions which are at the core of this renewed policy and its impact on India and her citizens.
                    </p>
                <ul className="entry_content">
                <li>In this webinar, we will deliberate the grey areas and significant upgrades of the proposed Bill.</li>
<li>One departure from the previous Bill are the concessions on cross-border data flows and a softer stand on data localisation requirements. What fostered this development as the same government was not in favor of data transfer earlier?</li>
<li>The new Bill’s acknowledgement of ‘withdraw consent’ recognises the right of a person to postmortem privacy. An important clause, that was missing from the 2019 Bill. What impact does this have on our people?</li>
<li>The developments indicate India is strengthening its stance on data protection, inspired by international best-practices, but there are still complaints of lacunae. How can the Government leverage its ever-strengthening role in the global order against the backdrop of the nation’s G20 Presidency?</li>
<li>What is the role of the Data Fiduciary as global tech giants continue to collect vast amounts of personal, private and identifiable data?</li>
<li>Does the draft Bill accord substantial citizen recourse? How accountable are the key stakeholders in the process of collecting and passing private data through multiple downstream channels?</li>
                 </ul>
                </Col>
            </Row>
        </Container>
     </section>

<section className="section_bg">
    <Container>
        <Row>
        <Col xl={12}>
                <h4 className="pseudo_border">Who should attend? </h4>
                        <div className="heading_border mb-4"> </div>

                </Col>
                <Col xl={12}>
                <ul className="entry_content">
                                    
                <li>Young Indians who are committed to bringing about change and building India</li>
<li>Those aspiring to build a career in politics, public policy, public administration, journalism, political consulting, public relations, image consulting and related fields</li>
<li>Researchers, academicians, journalists, and professionals in the fields mentioned above</li>
<li>Others who take a deep interest in the subject</li>

                </ul>
                </Col>
        </Row>
    </Container>
</section>
        </>
    );
}
export default DataProtectionBill;