import { Col, Container, Row } from "react-bootstrap";
function Octobertalkpolicy(){
    return(<>
    <div className="policy_talk">
    <section className="lets_talk oct_talk">
            <Container>
                
                <Row className="mt-5">
               
                    <Col xl={6} className="mt-5">

                    <p class="eventoutline">PANEL DISCUSSION</p>
                    <div className="d-flex panel">
                    <span><img src="./images/events/policy_series/innerPage/quote.png"></img></span>
                    <p class="LTP-txt">Let’s Talk <br/>
                      <span className="bold mb-5">Policy</span></p>
                    </div>
                        <p class="LTP-tit">New W.H.O norms on Air Quality</p>
                        <p class="LTP-tit">What can India do for clean air? </p>
                        <div className="d-flex panel_cal">
                           <span><img src="./images/events/policy_series/innerPage/evenicon1.png"></img><span class="LTP-tit">October 11, 2021</span></span> 
                            <span><img src="./images/events/policy_series/innerPage/evenicon2.png"></img><span class="LTP-tit">7 PM IST</span></span>
                        </div>
                    </Col>
                    <Col xl={6} className="mt-5">
                     <img src="./images/events/policy_series/innerPage/Banner_LP.png"></img>
                    </Col>
                </Row>

            </Container>
           </section>
           <section className="lets_talk modarate">
                <Container>
                    <div className="d-flex nidhi align-items-end">
                        <img src="./images/events/policy_series/innerPage/img-11.png"></img>
                        <div className="ml-3">
                            <h4>EVENT MODERATED BY</h4>
                            <h5>Nidhi Razdan</h5>
                            <p>Visiting Faculty, Kautilya<br/>
                                Former Executive Editor, NDTV</p>
                        </div>
                    </div>
                </Container>
            </section>
           <section className="about_evnt">
            <Container>
               <h2>About the event</h2>
               <p>A recently released WHO report states that air pollution exposure is estimated to cause 7 million premature deaths globally every year! It further elaborates that almost all of the global population (99%) breathes air that exceeds WHO guideline limits. Further, the combined effects of ambient (outdoor) and household air
                    pollution have increased mortality rates from stroke, heart disease, chronic obstructive pulmonary disease,lung cancer, and acute respiratory infections.</p>
               <p>In a bid to support countries to address air pollution, WHO has recently released stringent air quality guidelines, lowering the recommended levels of pollutants that can be considered safe for human health. The new guidelines recommend new air quality levels to protect the health of populations by reducing levels of key air pollutants, some of which also contribute to climate change. The goal of the guideline is for all countries to achieve recommended air quality levels, but the reality is far-fetched.</p>
               <p><b>Will all countries and regions struggling with high air pollution levels be able to adapt to these guidelines?</b></p>
               <p>In this Let’s Talk Policy session, we catch up with environmentalists <b>Anumita Roy Chowdhury</b> Executive Director, Research and Advocacy, Centre for Science & Environment; <b>Jyoti Pande Lavakare,</b> Co-founder, Care for Air, and <b>Bhargav Krishna</b>, Fellow, Centre for Policy Research to understandhow different regions will be impacted.</p>
               <p>The event was moderated by renowned journalist, <b>Nidhi Razdan.</b></p>
               </Container>
           </section>
           <section className="yellow_bg">
            <Container>
               <p><span>‘Let’s Talk Policy’</span> <b>is a panel discussion series initiated by Kautilya School of Public Policy</b>, that brings forth a select group of panellists, sharing differing views on key issues of national and global relevance, and building the dialogue into a conversation for the student community.</p>
               <p>The series aims to unravel major themes around democracy, politics, and human rights, and explore the challenges and rewards of implementing practices. We aim to create a cohesive platform and call on thematic experts to foster a healthy dialogue around the most-pressing issues.</p>
               </Container>
           </section>
           <section className="panellists">
            <Container>
                <h2>About the panellists</h2>
                <Row>
                    <Col xl={4} className="bg_red">
                        <img src="./images/events/policy_series/innerPage/Nidi.png"></img>
                        <div className="">
                        <h5>Nidhi Razdan</h5>
                        <p className="k-eventcard5-desig">Visiting Faculty, Kautilya &<br/>
                        Fmr. Exec. Editor NDTV</p>
                        <p className="k-eventcard5-role">Moderator</p>
                        <p className="k-eventcard5-desc">Nidhi Razdan (Moderator) is an award-winning journalist, author and the former Executive Editor of…</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red bg_black">
                        <img src="./images/events/policy_series/innerPage/Dilip-Chenoy_circle.png"></img>
                        <div className="">
                        <h5>Anumita_Roy_Chowdhury</h5>
                        <p className="k-eventcard5-desig">Exec Dir., Research and Advocacy,<br/>Center for Science & Env.</p>
                        <p className="k-eventcard5-role k-new">-------</p>
                        <p className="k-eventcard5-desc">Anumita Roy Chowdhury is the Executive Director, Research and Advocacy, at the Center for…</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>

                    <Col xl={4} className="bg_red bg_black">
                        <img src="./images/events/policy_series/innerPage/Jyoti_Pande_Lavakare.png"></img>
                        <div className="">
                        <h5>Jyoti Pande Lavakare</h5>
                        <p className="k-eventcard5-desig">Co-founder, Care for Air</p>
                        <p className="k-eventcard5-role k-new">-----</p>
                        <p className="k-eventcard5-desc">Jyoti Pande Lavakare is a columnist,financial journalist and writer who has lived and worked….</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red">
                        <img src="./images/events/policy_series/innerPage/Bhargav_Krishna.png"></img>
                        <div className="">
                        <h5>Bhargav Krishna</h5>
                        <p className="k-eventcard5-desig">Fellow, Centre for Policy Research</p>
                        <p className="k-eventcard5-role">-----</p>
                        <p className="k-eventcard5-desc">Bhargav Krishna is a Fellow at the Centre for Policy Research. His research interests span….</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                </Row>
               
            </Container>
           </section>
           <section className="attendees">
            <Container>
                <h2>Attendees</h2>
                <p>Young Indians, passionate about bringing about positive change and building India</p>
                <p>Those aspiring to build a career in public life, public policy and administration, social sector, media, journalism and communications, politics, and other related fields</p>
                <p>Academicians, researchers and journalists working in or following the fields mentioned above</p>
                <p>Those watching the unfolding Afghanistan crisis closely – and are keen to hear from experts with relevant experience at the highest level</p>
            </Container>
           </section>
           <section className="bg_black_sign">
            <Container>
                <p>Sign-up for this exclusive panel discussion TODAY.<br/> Hear fresh perspectives from some of India’s most revered personalities!</p>
            </Container>
           </section>
    </div>

    
    </>
    );
}
export default Octobertalkpolicy;