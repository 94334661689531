import { Col, Container, Row } from "react-bootstrap";

function UniteDividedg20 (){
    return(
        <>
        <section className="uniteDivide">
            <Container>
                <Row>
                    <Col xl={12} className="m-5 p-5"></Col>
                    <Col xl={12}>
                    <div class="top-div">
<h4 class="cus-public"><strong>Let’s Talk Policy</strong></h4>
<h4 class="k-h2 eventsubtitle-lineheighttit" style={{color : "#b11016"}}>Can India Unite<br/>
a Divided G20?</h4>
<h4 className="cus-time text-dark">07:00 pm – 8:00 pm IST<br/>
24th January 2023 (Via Zoom)</h4>
</div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section>
            <Container className="wpb_single_image ">
                <Row>
                    <Col xl={3}>
                       <img src="./images/events/policy_series/innerPage/uniteDivide/1.png" />
                    </Col>
                    <Col xl={3}>
                       <img src="./images/events/policy_series/innerPage/uniteDivide/2.png" />
                    </Col>
                    <Col xl={3}>
                       <img src="./images/events/policy_series/innerPage/uniteDivide/3.png" />
                    </Col>
                    <Col xl={3}>
                       <img src="./images/events/policy_series/innerPage/uniteDivide/4.png" />
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="uniteDivide2">
<Container>
    <Row>
        <Col xl={12}>
           <div className="m-5">
        <h4 className="eventsubtitle-lineheight2  txt-white"><strong>AT THE G20 HELM AMID GLOBAL TURMOIL</strong></h4>
        <div class="heading_border_white mb-4"> </div>
        <p className="text-white">
        The G20 grouping represents two-thirds of the world’s population and about 85% of the global GDP.
         India took over its presidency last year amid heightened tension over the Russia-Ukraine conflict and an uneasy relationship with China</p>
        
         <p className="text-white">
         Global economic upheaval and the road to recovery from the Covid-19 pandemic have also strained multilateralism.
Over the course of the year, India has the opportunity to steer the G20 grouping through these inflection points while looking out for its own strategic interests
            </p> </div> 
        </Col>
    </Row>
</Container>
</section>

<section>
        <Container>
            <Row>
                <Col xl={12}>
                <h4 className="pseudo_border">WHAT WILL BE COVERED</h4>
                        <div className="heading_border mb-4"> </div>

                </Col>
                <Col xl={12}>
                    <p>
                    In this episode of Let’s Talk Policy,
                     our panel experts will discuss and answer questions which are at the core of this renewed policy and its impact on India and her citizens.
                    </p>
                <ul className="entry_content">
                <li>The G20 summit in Bali last year, where India took over the presidency, saw a deeply divided grouping. Can India leverage its unique position of sharing warm ties with both the US-led West and Russia to bridge this divide?</li>
                <li>Can India mediate between the warring sides to help negotiate a resolution to the conflict?</li>
                <li>How can the G20 presidency be an opportunity for India to pitch its long-held proposition of a multipolar global order?</li>
                <li>Can India lead the Global South (broadly covering regions of Asia, Africa, Oceania and Latin America)?</li>
                <li>What are the key challenges for its foreign policy in the year ahead?</li>
                <li>How can G20 address challenges left in the wake of the pandemic, economic devastation and social upheaval?</li>
                <li>How can India ensure that the G20 summit scheduled to be held in Delhi in September results in fruitful negotiations?</li>
                    </ul>
                </Col>
            </Row>
        </Container>
     </section>

<section className="section_bg">
    <Container>
        <Row>
        <Col xl={12}>
                <h4 className="pseudo_border">WHO WILL BENEFIT </h4>
                        <div className="heading_border mb-4"> </div>

                </Col>
                <Col xl={12}>
                <ul className="entry_content">
                                    
                <li>Students of foreign trade, business, policy and international relations</li>
<li>Aspiring journalists, researchers, academicians and professionals</li>
<li>Political commentators, public service professionals</li>
<li>Entrepreneurs</li>

                </ul>
                </Col>
        </Row>
    </Container>
</section>
        </>
    );
}
export default UniteDividedg20;