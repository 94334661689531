import { Col, Container, Row } from "react-bootstrap";
function Letstalkpolicy(){
    return(<>
    <div className="policy_talk">
    <section className="lets_talk">
            <Container>
                
                <Row className="mt-5">
               
                    <Col xl={6} className="mt-5">

                    <p class="eventoutline">PANEL DISCUSSION</p>
                    <div className="d-flex panel">
                    <span><img src="./images/events/policy_series/innerPage/quote.png"></img></span>
                    <p class="LTP-txt">Let’s Talk <br/>
                      <span className="bold mb-5">Policy</span></p>
                    </div>
                        <p class="LTP-tit">Should India do business with the Taliban?</p>
                        <p class="LTP-tit">What’s at stake for India?</p>
                        <div className="d-flex panel_cal">
                           <span><img src="./images/events/policy_series/innerPage/evenicon1.png"></img><span class="LTP-tit">September 4, 2021</span></span> 
                            <span><img src="./images/events/policy_series/innerPage/evenicon2.png"></img><span class="LTP-tit">7 PM IST</span></span>
                        </div>
                    </Col>
                    <Col xl={6} className="mt-5">
                     <img src="./images/events/policy_series/innerPage/LP-top-banner-PNG-1.png"></img>
                    </Col>
                </Row>

            </Container>
           </section>
           <section className="about_evnt">
            <Container>
               <h2>About the event</h2>
               <p>Taliban’s swift takeover of Afghanistan shocked the world. For India, the house-of-cards collapse of the 20-year-old Afghan democracy represents a strategic setback.</p>
               <p>Does this mean an end of trade relations with Afghanistan, a critical strategic ally for India in South Asia? The Indian government has made significant investments in the war-torn country over the past two decades and provided the government with much-needed developmental assistance in terms of investments and bilateral trade. The Taliban seizing control in Afghanistan presents a bleak future for such projects in Afghanistan. Moreover, India has not had a working relationship with the Taliban in the past.</p>
               <p><b>While India is currently following the ‘wait and watch’ approach, the bigger question is whether India should engage with the Taliban now?</b></p>
               <p>Hear all about it from our expert panellists in this <b>Let’s Talk Policy</b> session – Navtej Sarna, Former Diplomat & Author, <b>Suhasini Haider</b>, Diplomatic Affair Editor, The Hindu, <b>Amitabh Mattoo</b>, Professor of International Relations at JNU & University of Melbourne and <b>Dr. Shanthie Mariet D’Souza</b>, Founding Professor, Kautilya.</p>
               <p>The event was moderated by renowned journalist, <b>Nidhi Razdan.</b></p>
               </Container>
           </section>
           <section className="yellow_bg">
            <Container>
               <p><span>‘Let’s Talk Policy’</span> <b>is a panel discussion series initiated by Kautilya School of Public Policy</b>, that brings forth a select group of panellists, sharing differing views on key issues of national and global relevance, and building the dialogue into a conversation for the student community.</p>
               <p>The series aims to unravel major themes around democracy, politics, and human rights, and explore the challenges and rewards of implementing practices. We aim to create a cohesive platform and call on thematic experts to foster a healthy dialogue around the most-pressing issues.</p>
               </Container>
           </section>
           <section className="panellists">
            <Container>
                <h2>About the panellists</h2>
                <Row>
                    <Col xl={4} className="bg_red">
                        <img src="./images/events/policy_series/innerPage/Nidi.png"></img>
                        <div className="">
                        <h5>Nidhi Razdan</h5>
                        <p className="k-eventcard5-desig">Visiting Faculty, Kautilya &<br/>
                        Fmr. Exec. Editor NDTV</p>
                        <p className="k-eventcard5-role">Moderator</p>
                        <p className="k-eventcard5-desc">Nidhi Razdan (Moderator) is an award-winning journalist, author and the former Executive Editor of…</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red bg_black">
                        <img src="./images/events/policy_series/innerPage/Navtej.png"></img>
                        <div className="">
                        <h5>Navtej Singh Sarna</h5>
                        <p className="k-eventcard5-desig">Former Indian Ambassador to the US & Israel<br/>
                        Fmr. Exec. Editor NDTV</p>
                        <p className="k-eventcard5-role k-new">-------</p>
                        <p className="k-eventcard5-desc">Navtej Singh Sarna diplomatic appointment was as India’s Ambassador to the …</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red">
                        <img src="./images/events/policy_series/innerPage/Suhasini.png"></img>
                        <div className="">
                        <h5>Suhasini Haidar</h5>
                        <p className="k-eventcard5-desig">Diplomatic Affair Editor<br/>
                        The Hindu</p>
                        <p className="k-eventcard5-role ">------</p>
                        <p className="k-eventcard5-desc">Suhasini Haidar has previously served as the Foreign Affairs editor and prime time anchor for …</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red bg_black">
                        <img src="./images/events/policy_series/innerPage/Amitabh.png"></img>
                        <div className="">
                        <h5>Amitabh Mattoo</h5>
                        <p className="k-eventcard5-desig">Prof., Int’l Relations<br/>JNU & Univ of Melbourne</p>
                        <p className="k-eventcard5-role k-new">-----</p>
                        <p className="k-eventcard5-desc">Amitabh Mattoo former Vice Chancellor of University of Jammu, has a Doctorate from the …</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                    <Col xl={4} className="bg_red">
                        <img src="./images/events/policy_series/innerPage/Shanthie.png"></img>
                        <div className="">
                        <h5>Dr. Shanthie Mariet D’Souza</h5>
                        <p className="k-eventcard5-desig">Founding Professor,<br/>
                        Kautilya School of Public Policy</p>
                        <p className="k-eventcard5-role">-----</p>
                        <p className="k-eventcard5-desc">Dr. Shanthie Mariet D’Souza is Founder of Mantraya; Visiting Faculty at the Naval War …</p>
                        <p><a class="k-eventcard5-readmore n-nidhi" href="#">Read more</a></p>
                        </div>
                    </Col>
                </Row>
               
            </Container>
           </section>
           <section className="attendees">
            <Container>
                <h2>Attendees</h2>
                <p>Young Indians, passionate about bringing about positive change and building India</p>
                <p>Those aspiring to build a career in public life, public policy and administration, social sector, media, journalism and communications, politics, and other related fields</p>
                <p>Academicians, researchers and journalists working in or following the fields mentioned above</p>
                <p>Those watching the unfolding Afghanistan crisis closely – and are keen to hear from experts with relevant experience at the highest level</p>
            </Container>
           </section>
    </div>

    
    </>
    );
}
export default Letstalkpolicy;